@import './swiper.scss';

// .contact-input {
//   .react-tel-input .special-label {
//     color: #394d86 !important;
//     background: transparent;
//     margin-left: -26px;
//   }
//   .react-tel-input .form-control {
//     width: 100%;

//     background: transparent;
//     border-color: transparent;
//     color: #394d86 !important;
//     border-bottom: 1px solid #0000006b !important;
//     padding: 12px 18px 12px 50px;
//   }
//   .react-tel-input .country-list {
//     max-height: 110px !important;
//   }
//   .react-tel-input .special-label {
//     font-size: 12px;
//   }
// }

.contact-input.react-tel-input {
  margin-bottom: 6px;
  margin-top: 16px;

  .special-label {
    color: #394d86 !important;
    background: transparent;
    margin-left: -26px;
  }

  .form-control {
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: #394d86 !important;
    border-bottom: 1px solid #0000006b !important;
    padding: 12px 18px 12px 50px;
    border-radius: unset;
  }

  .country-list {
    max-height: 110px !important;
  }

  .special-label {
    font-size: 12px;
  }

  .invalid-number-message {
    left: -4px;
    top: 47px;
    color: #d32f2f;
    font: 400 0.75rem/1.66 Poppins, sans-serif;
  }

  .form-control:focus {
    box-shadow: unset;
  }
}

.uploaded-row {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  margin: 12px -18px -12px 0px;
  overflow: unset;
  width: 100%;

  .img-container {
    width: 25%;
    padding-right: 18px;
    height: fit-content;
    position: relative;
    margin-bottom: 12px;
    text-align: center;

    .remove {
      position: absolute;
      right: 6px;
      top: -20px;
      border-radius: 100px;
      cursor: pointer;
      color: white;
      text-align: center;
      transform: rotate(45deg);
    }

    img {
      object-fit: contain;
      width: 100%;
      border: 1px solid #e2e2e2;
      height: 80px;
      border-radius: 3px;
      background: #eaeaea;
    }

    .file-name {
      line-height: 14px;
      font-size: 10px;
      color: #474747;
      font-weight: 500;
      white-space: normal;
      overflow: hidden;
      text-overflow: unset;
      // word-break: break-all;
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 8%;
}

.swiper-pagination-bullet-active {
  background-color: #ff9800 !important;
}

.eSign-canvas {
  background-color: #e8ebf4;
  width: 100%;
  min-height: 300px;
  @media screen and (min-width: 600px) {
    max-height: 300px;
  }
}
.clear-canvas {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  position: absolute;
  right: 40px;
  margin-top: -7px;
  background-color: #ff9800;
}

@media screen and (max-width: 400px) {
  .border-googleMap {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  .inner-googleMap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@media screen and (min-width: 401px) {
  .border-googleMap {
    width: 100%;
  }

  .inner-googleMap {
    width: 100%;
    height: 325px;
  }
}

.contact-input.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.6) !important   ;
}

.contact-input.react-tel-input .form-control {
  color: black !important;
}

.responsive-webCam {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.vr-font {
  background: white;
  color: #f7982f;
  font-weight: bold;
  padding: 2px 3px;
  border-radius: 3px;
  font-size: 10px;
  letter-spacing: 1px;
  margin-right: 4px;
}

.incomplete-info {
  color: red;
}

.bumi {
  width: 14px;
  margin-left: 2px;
}

.text-icon {
  display: 'flex';
  align-items: 'center';
  overflow: 'hidden';
  span: {
    white-space: 'nowrap';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
  }
  svg: {
    margin-right: '4px';
    font-size: '10px';
    line-height: '14px';
  }
}

.vr-button {
  position: absolute !important;
  z-index: 2;
  right: 10px;
  top: 10px;
}
