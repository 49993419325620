.swiper-container {
  .swiper-pagination {
    background-color: transparent !important;
  }

  .swiper-navigation-color .swiper-theme-color {
    background-color: #ff9800 !important;
  }

  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    size: 20px;
    background: white;
    border-radius: 100px;
    height: 36px;
    width: 36px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 18px;
    font-weight: 900;
    color: #ff9800 !important;
  }
}
